﻿.delay-02s {
    animation-delay: 0.2s;
    -webkit-animation-delay: 0.2s;
}

.delay-05s {
    animation-delay: 0.5s;
    -webkit-animation-delay: 0.5s;
}

.delay-07s {
    animation-delay: 0.7s;
    -webkit-animation-delay: 0.7s;
}
