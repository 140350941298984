@import 'animations';
@import 'variables';
@import 'lib/bootstrap/scss/bootstrap.scss';

$ff-opensans: 'Open Sans', sans-serif;
$ff-monserrat: 'Monserrat', sans-serif;

body {
    background: $white;
    font-family: $ff-opensans;
    font-size: 14px;
    font-weight: normal;
    color: #888888;
    margin: 0;
}

h2 {
    font-size: 34px;
    color: #222222;
    font-family: $ff-monserrat;
    font-weight: 700;
    letter-spacing: -1px;
    margin: 0 0 15px 0;
    text-align: center;
    text-transform: uppercase;
}

h3 {
    font-family: $ff-monserrat;
    color: #222222;
    font-size: 16px;
    margin: 0 0 5px 0;
    text-transform: uppercase;
    font-weight: 400;
}

h6 {
    font-size: 16px;
    color: #888888;
    font-family: $ff-opensans;
    font-weight: 400;
    text-align: center;
    margin: 0 0 60px 0;
}

p {
    line-height: 24px;
    margin: 0;
}

.navbar-fixed-padding {
    padding-top: 72px;
}

.header {
    text-align: center;
    background: url(/img/pw_maze_black_2X.png) left top repeat;
    padding: 280px 0;

    h1 {
        font-family: $ff-monserrat;
        font-size: 50px;
        font-weight: 400;
        letter-spacing: -1px;
        margin: 0 0 22px 0;
        color: $white;
    }
}

.we-create {
    padding: 0;
    margin: 35px 0 55px;

    li {
        display: inline-block;
        width: 50%;
        font-family: $ff-monserrat;
        font-size: 14px;
        color: #bcbcbc;
        text-transform: uppercase;
        font-weight: 400;
        margin: 0 5px 0 0;
        padding: 0 0 0 15px;

        &:first-child {
            background: none;
        }
    }
}

.logo {
    width: 130px;
    margin: 0 auto 35px;
}

.nav-item {
    margin: 0 1px;
}

.main-section {
    padding: 90px 0 110px;
}

#main-nav {
    a.nav-link {
        display: inline-block;
        color: #222222;
        text-transform: uppercase;
        font-family: $ff-monserrat;
        text-decoration: none;
        line-height: 20px;
        margin: 17px 32px;
        transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;

        &:hover {
            color: #7cc576;
        }
    }
}

.stats {
    font-size: 3em;
}

#contact {
    background: $gray-100 url('/img/bg-map.png') left 190px no-repeat;
    padding: 90px 0 100px;

    h3 {
        font-size: 15px;
        font-weight: 400;
        width: 102px;
        margin-right: 12px;
        line-height: 28px;

        i, svg {
            font-style: normal;
            font-size: 18px;
            color: #222222;
            font-family: 'FontAwesome';
            font-weight: normal;
        }
    }

    span {
        line-height: 28px;
        display: block;
        overflow: hidden;
    }

    .contact-info-box {
        font-size: 15px;
        margin: 0 0 14px 68px;
        padding-left: 0;
    }
}

.serviceList {
    i, svg {
        font-style: normal;
        font-size: 38px;
        display: block;
        color: #222222;
        font-family: 'FontAwesome';
        line-height: 38px;
    }

    .row {
        margin-bottom: 40px;
    }
}

#team {
    h3 {
        font-size: 24px;
    }

    span {
        margin-bottom: 24px;
        display: block;
    }

    .team-member {
        max-width: 400px;
        margin: 0 25px;
        text-align: center;
    }
}

footer {
    background: url(/img/pw_maze_black_2X.png) left top repeat;
    padding: 35px 0 35px;

    .logo {
        margin: 15px auto 35px;
    }

    .copyright, .credits {
        color: #cccccc;
        font-size: 14px;
        display: block;
        text-align: center;
    }
}